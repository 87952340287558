<template>
  <div class="flow-orient-form-wrapper">
    <!-- 设备 -->
    <a-form-model-item label="设备">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.equipmentRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-select-more
          class="rule"
          v-model="formquery.equipmentRules"
          :allData="brandList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="设备品牌"
          :showLabel="false"
        />
      </div>
    </a-form-model-item>
    <!-- 安装列表 -->
    <a-form-model-item label="安装列表">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.installRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-select-more
          class="rule"
          v-model="formquery.installRules"
          :allData="installAppList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="安装列表"
          :showLabel="false"
        />
      </div>
    </a-form-model-item>
    <!-- 国家/地区 -->
    <a-form-model-item label="国家/地区">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.countryRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-select-more
          class="rule"
          v-model="formquery.countryRules"
          :allData="countryList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="国家/地区"
          :showLabel="false"
        />
      </div>
    </a-form-model-item>
    <!-- 省份 -->
    <a-form-model-item label="省份" v-if="hasChina">
      <div class="form-list-item">
        <a-select placeholder="请选择类型" v-model="formquery.areaRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <el-cascader
          class="rule"
          v-model="formquery.areaRules"
          placeholder="请选择省份"
          :options="areaList"
          :props="{
            multiple: true
          }"
          collapse-tags
        />
      </div>
    </a-form-model-item>
    <!-- 包名 -->
    <a-form-model-item label="包名">
      <div class="form-list-item start-y">
        <a-select placeholder="请选择类型" v-model="formquery.bundleRuleType" class="type">
          <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <m-string-list class="rule" placeholder="多个包名以换行或中英文逗号分隔" v-model="formquery.bundleRules"/>
      </div>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getAreaList } from '@/apiForManage/common'
export default {
  name: 'FlowOrientForm',
  data () {
    return {
      areaList: [],
      optionsStr2: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        }
      ],
      // 表单默认值
      formqueryTemp: {
        equipmentRuleType: 0,
        equipmentRules: [],
        installRuleType: 0,
        installRules: [],
        countryRules: [],
        countryRuleType: 0,
        areaRuleType: 0,
        areaRules: []
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    },
    hasChina () {
      return this.formquery.countryRules.includes('CHN')
    }
  },
  props: {
    query: {
      default: () => ({
        // 全为undefined
        equipmentRuleType: 0,
        equipmentRules: [],
        installRuleType: 0,
        installRules: [],
        countryRules: [],
        countryRuleType: 0,
        areaRuleType: 0,
        areaRules: []
      }),
      type: Object
    },
    brandList: {
      default: () => [],
      type: Array
    },
    installAppList: {
      default: () => [],
      type: Array
    },
    countryList: {
      default: () => [],
      type: Array
    }
  },
  created () {
    this.getAreaList()
  },
  methods: {
    // 获取地区列表
    async getAreaList () {
      const { data = [] } = await getAreaList()
      this.areaList = data
    }
  }
}
</script>

<style lang="less" scoped>
.flow-orient-form-wrapper {
  .form-list-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    &.start-y{
      align-items: flex-start;
    }
    .type {
      width: 31%;
      margin-right: 20px;
    }
    .rule{
      flex: 1 auto;
    }
  }
}
</style>
